<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-card-title>
            Отчет по утилизации отходов (СД)
          </v-card-title>
          <v-card-subtitle>
            Информация об отчете
          </v-card-subtitle>
          <div>
            <v-row class="mx-1">
              <v-col>
                <label>
                  Дата начала
                  <custom-date-picker v-model="from" />
                </label>
              </v-col>
              <v-col>
                <label> Дата окончания
                  <custom-date-picker v-model="to" />
                </label>
              </v-col>
            </v-row>

            <v-card-actions class="mt-10 mx-2">
              <v-btn
                class="elevation-0"
                dark
                :loading="loading"
                :disabled="loading"
                @click="downloadReport"
              >
                <v-icon>mdi-download</v-icon> Скачать
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import CustomDatePicker from '@/components/shared/custom-date-picker.vue';
import { downloadExcel, getFilenameFromContentDisposition} from '@/helpers/documents'
import { mapGetters } from 'vuex';
import moment from 'moment'

export default {
  components:{CustomDatePicker},
  data:() =>({
    from:null,
    to:null,
    loading:false,
  }),
  computed:{
    ...mapGetters({
      user:'auth/getUser',
      divisions:'auth/getDivisions'
    })
  },
  methods:{
    downloadReport(){
      this.loadingStart()

      const unp = this.getCurrentCompanyUnp()
      const from = moment(this.from).isValid()
        ? moment(this.from).format("DD.MM.YYYY")
        : null;
      const to = moment(this.to).isValid()
        ? moment(this.to).format("DD.MM.YYYY")
        : null;

      if(!from || !to){
        this.loadingEnd()
        return this.$snackbar({text:'Неверная дата', color:'red', top:false})
      }
      if(!unp){
        this.loadingEnd()
        return this.$snackbar({text:'Ошибка получения УНП', color:'red', top:false})
      }

      this.$store.dispatch('statistics/recyclingReport', {unp, from, to}).then(res => {
        const name = getFilenameFromContentDisposition(res)
        downloadExcel(res.data, name)
      }).catch(() => {
        this.$snackbar({text:'Не удалось сохранить файл', color:'red', top:false})
      }).finally(() => {
        this.loadingEnd()
      })
    },
    loadingStart(){
      this.loading = true
    },
    loadingEnd(){
      this.loading = false
    },
    getCurrentCompanyUnp(){
      const divisionId = this.user.user.activeDivisionId
      const division = this.divisions.find(i => i.real_division_id === divisionId)
      return division?.tax_payer_num ?? null
    }
  }

};
</script>
